import httpService from "@/request"

//分类
// 查询所有的设施/设备分类
export function getFacilitiesList(params) {
	return httpService({
		url: `/user/facilitiesCategory/list`,
		method: 'get',
		params: params,
	})
}

// 添加设施/设备分类信息
export function addFacility(params) {
	return httpService({
		url: `/user/facilitiesCategory/insert`,
		method: 'post',
		data: params,
	})
}

// 批量删除设施/设备分类
export function delFacility(params) {
	return httpService({
		url: `/user/facilitiesCategory/delete`,
		method: 'post',
		data: params,
	})
}

// 根据设施/设备分类主键id查询设施/设备分类信息
export function findFacility(params) {
	return httpService({
		url: `/user/facilitiesCategory/findById`,
		method: 'get',
		params: params,
	})
}

// 修改设施分类信息(不允许修改设施分类类型)
export function updateFacility(params) {
	return httpService({
		url: `/user/facilitiesCategory/update`,
		method: 'post',
		data: params,
	})
}

//管理
// 查询所有的设施/设备管理
export function facilityInfoList(params) {
	return httpService({
		url: `/user/facilitiesManage/list`,
		method: 'get',
		params: params,
	})
}

// 添加设施/设备信息
export function addFacilityInfo(params) {
	return httpService({
		url: `/user/facilitiesManage/insert`,
		method: 'post',
		data: params,
	})
}

// 根据设施/设备主键id查询设施/设备信息
export function findFacilityInfo(params) {
	return httpService({
		url: `/user/facilitiesManage/findById`,
		method: 'get',
		params: params,
	})
}

// 根据设施/设备主键id查询设施/设备情况list记录
export function findRecordList(params) {
	return httpService({
		url: `/user/facilitiesManage/situationList`,
		method: 'get',
		params: params,
	})
}

// 修改设施/设备信息
export function updateFacilityInfo(params) {
	return httpService({
		url: `/user/facilitiesManage/update`,
		method: 'post',
		data: params,
	})
}

// 批量删除设施/设备信息
export function delFacilityInfo(params) {
	return httpService({
		url: `/user/facilitiesManage/delete`,
		method: 'post',
		data: params,
	})
}

//预约 
//查询所有的设施设备预约
export function getFacilitiesReserve(params) {
	return httpService({
		url: `/user/facilitiesReserve/list`,
		method: 'get',
		params: params,
	})
}
