<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <!-- <span>{{form.type == 1?'设施管理':'设备管理'}}</span> -->
                <a-radio-group
                v-model="form.type"
                button-style="solid"
                @change="typeChange"
                >
                <a-radio-button :value="1"> 设施管理 </a-radio-button>
                <a-radio-button :value="2"> 设备管理 </a-radio-button>
                </a-radio-group>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.name" style="width: 200px" :placeholder="form.type == 1 ?'设施分类名称':'设备分类名称'"></a-input>
                    <a-input v-model="form.code" style="width: 200px" :placeholder="form.type == 1 ?'设施编号':'设备编号'"></a-input>
                    <a-range-picker @change="handlerTime" v-model="selTime" style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['添加开始时间','添加结束时间']"></a-range-picker>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="detailShow = true;mode = 1">添加分类</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange" :scroll="{ x: 1400 }"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="action" slot-scope="text,record">
                    <span><a @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="form.type==1&&mode==1?'添加设施分类':form.type==2&&mode==1?'添加设备分类':form.type==1&&mode==2?'编辑设施分类':'编辑设备分类'" 
            :width="540" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="24">
                        <a-form-model-item label="设施名称" prop="name">
                            <a-input style="width: 300px" placeholder="请输入模版名称" v-model="addForm.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="设施图片">
                            <commonUpload :fileList='fileList' @handleChange="handleChange" />
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {getFacilitiesList,addFacility,updateFacility,delFacility,findFacility} from "@/api/operation/facility"
import { form ,addForm, rules, columns } from "./depend/config";
export default {
    data() {
        return {
            form:form,
            columns: columns,
            rules: rules,
            tableData: [],
            selTime: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            selectedRowKeys: [],
            //新增编辑
            addForm: addForm,
            detailShow: false,
            mode: 1,
            activeId: undefined,
            fileList: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let obj = Object.assign(this.form, {pageNum:this.pagination.current,size:this.pagination.pageSize})
            getFacilitiesList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form.name = ''
            this.form.code = ''
            this.form.createStartDate = undefined
            this.form.createEndDate = undefined
            this.selTime = []
            this.getData()
        },
        handlerTime(val) {
            this.form.createStartDate = val[0];
            this.form.createEndDate = val[1];
        },
        typeChange() {
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的分类？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delFacility({facilitiesCategoryIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        del(val) {
            this.$confirm({
                title: "是否删除该分类？",
                icon:'delete',
                onOk:async()=>{
                    let res = await delFacility({facilitiesCategoryIds: [val.id]})
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                },
            })
        },
        //新增编辑
        edit(val) {
            this.mode = 2;
            findFacility({facilitiesCategoryId: val.id}).then(res => {
                let data = res.data;
                this.addForm.name = data.name;
                if(data.imgList.length>0){
					const pic = []
					this.addForm.imgUrls.push(data.imgList[0].url)
					for(let item of data.imgList){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						pic.push(obj)
					}
					this.fileList = pic
				}
                this.activeId = data.id
            })
            this.detailShow = true;
        },
        addConfirm() {
            if(this.mode == 1) {
                let obj = Object.assign(this.addForm,{type: this.form.type})
                addFacility(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else if (this.mode == 2) {
                let obj = Object.assign(this.addForm,{id: this.activeId})
                updateFacility(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        detailClose() {
            this.addForm = {
                name: '',
                imgUrls: [],
            };
            this.fileList = [];
            this.detailShow = false;
        },
        //图片上传
        handleChange(data) {
            this.fileList = data;
			this.addForm.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.addForm.imgUrls.push(ele.response.data)
				}
			})
        }
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">

</style>