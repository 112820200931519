export const form= {
	type: 1,
	name: '',
	code: '',
	createStartDate: undefined,
	createEndDate: undefined,
}

export const addForm = {
	name: '',
    imgUrls: [],
}

export const columns = [
	{
		title: "分类编号",
		width: "18%",
		dataIndex: "code",
	},
	{
		title: "分类名称",
		width: "12%",
		dataIndex: "name",
	},
	// {
	// 	title: "分类类型",
	// 	width: "9%",
	// 	dataIndex: "type",
	// },
    {
		title: "添加人名称",
		width: "15%",
		dataIndex: "createName",
	},
	{
		title: "设施数量",
		width: "12%",
		dataIndex: "num",
	},
	{
		title: "添加时间",
		width: "16%",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "240",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	name: [{ required: true, message: "请输入名称", trigger: "blur" }],
	content: [{ required: true, message: "请输入内容", trigger: "blur" }],
}

export function doStr(str, number) {
	if(str.length >= number) {
		return str.substr(0,number-1) + '...' 
	} else {
		return str
	}
}